import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  selectPlaygroundProfile,
  fetchPlayerData,
} from "../../../redux/slices/playground"
import { getAuthUser } from "../../../redux/slices/session/selectors"
import axios from "axios"
import useSWR from "swr"
import { FormattedMessage } from "react-intl"

import apiConstant from "../../../api/constant"

import loading from "../../../../assets/images/loading.gif"
import LeaderboardRow from "./leaderboardRow"
import styled from "styled-components"

const Styles = styled.div`
  h1 {
    text-align: center;
    font-size: 48px;
    @media (max-width: 375px) {
      font-size: 36px;
    }
    background-image: linear-gradient(135deg, #ec006a 0%, #f66c3d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    color: "transparent";
    margin-top: 4rem;
  }
  .loader {
    display: flex;
    justify-content: center;
    > img {
      width: 80%;
    }
  }
`

export default function index() {
  const { data } = useSWR(
    apiConstant.API_BASE_URL + "/playground-challenge/leaderboard/",
    axios,
    { refreshInterval: 10000 }
  )
  const { account } = useSelector((state) => ({
    account: getAuthUser(state),
  }))
  const user = useSelector(selectPlaygroundProfile)
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchPlayerData())
    }
  }, [])

  return (
    <Styles>
      <h1>
        <FormattedMessage id="playground.leaderboard.top30" />
      </h1>
      {!data ? (
        <div className="loader">
          <img src={loading} alt="COMPFEST" />
        </div>
      ) : (
        data.data.map((player, idx) => (
          <LeaderboardRow
            key={idx}
            index={idx + 1}
            playerName={player.display_name}
            points={player.points}
            isYou={user && player.display_name === user.displayName}
          />
        ))
      )}
    </Styles>
  )
}
