import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import PlaygroundPage from "../../components/PlaygroundPage"
import StickyPointsBar from "../../components/PlaygroundPage/stickyPointsBar"
import LeaderboardContent from "../../components/PlaygroundPage/Leaderboard"
import SponsorMedpar from "../../components/SponsorMedpar"

const Leaderboard = () => (
  <Layout>
    <SEO title="Leaderboard" />
    <div className="layout">
      <PlaygroundPage />
      <StickyPointsBar place />
      <LeaderboardContent />
      <SponsorMedpar />
    </div>
  </Layout>
)

export default Leaderboard
