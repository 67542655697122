import React from "react"
import styled from "styled-components"
import { FormattedMessage } from "react-intl"

import points from "../../../../assets/images/playground-assets/points-white.svg"

const Styles = styled.div`
  background: #2e2e2e;
  border-radius: 10px;
  padding: 1.5rem;
  margin: 1.5rem 0.625rem;
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  color: #fff;
  .numbering {
    font-size: 48px;
    font-weight: bold;
    margin: 0 2rem;
    min-width: 59px;
    text-align: center;
  }
  .rank {
    display: flex;
    flex-grow: 1;
    .playerName {
      flex-grow: 1;
      font-size: 24px;
      font-weight: bold;
      overflow: hidden;
    }
    .points {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: bold;
      text-align: right;
      img {
        margin-right: 0.5rem;
      }
    }
  }
  @media (max-width: 768px) {
    .numbering {
      font-size: 36px;
      margin: 0 1.5rem 0 1rem;
      min-width: 44.5px;
      @media (max-width: 375px) {
        margin: 0 1rem 0 0;
      }
    }
    .rank {
      flex-direction: column;
      .playerName {
        font-size: 20px;
      }
      .points {
        font-size: 20px;
        img {
          height: 14px;
        }
      }
    }
  }
`
const You = {
  border: "4px solid #FFCB15",
  fontWeight: "bold",
  color: "#FFCB15",
  borderRadius: "10px",
  textAlign: "center",
  paddingTop: ".75rem",
}

export default function leaderboardRow(props) {
  const topThree = () => {
    if (props.index === 1) {
      return {
        background:
          "linear-gradient(135deg, #F7B321 0%, #F12711 100%, #F12711 100%)",
      }
    } else if (props.index === 2) {
      return { background: "linear-gradient(135deg, #F06A3C 0%, #EC006A 100%)" }
    } else if (props.index === 3) {
      return { background: "linear-gradient(135deg, #E6195B 0%, #992F8B 100%)" }
    } else return {}
  }
  const rank = topThree()
  return (
    <div style={props.isYou ? You : {}}>
      {props.isYou && (
        <span>
          <FormattedMessage id="playground.leaderboard.you" />
        </span>
      )}
      <Styles style={props.isYou ? { ...rank, marginTop: ".75rem" } : rank}>
        <div className="numbering">{props.index}</div>
        <div className="rank">
          <div className="playerName">{props.playerName}</div>
          <div className="points">
            <img src={points} alt="points" /> {props.points}
          </div>
        </div>
      </Styles>
    </div>
  )
}
